:root {
    --calendar-bg: rgb(6, 13, 19);
    --calendar-white: rgba(255, 255, 255, 0.87);
    --calendar-border: 1px solid rgb(38, 45, 52);
    --calendar-selected-span: rgba(29, 48, 74, 0.65);
    --calendar-selcted: rgba(68, 199, 103, 1);
    --calendar-selcted_hover: rgba(68, 199, 103, 0.85);
    --calendar-hover-span: rgb(29, 48, 74);
    --calendar-nav-button-bg: rgba(68, 199, 103, 0.85);
    --app-height: 100%;
}

html, body {
    width: 100vw;
    height: var(--app-height);
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: #f3f3f3 !important;
    background-color: rgb(18, 29, 39) !important;
}

::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 10px !important;
    height: 10px !important;
    background-color: rgba(255, 255, 255, 0.08) !important;
}

::-webkit-scrollbar-track {
    background: rgb(18, 29, 39) !important;
}

::-webkit-scrollbar-corner {
    background: rgb(18, 29, 39) !important;
}

::-webkit-scrollbar-thumb {
    border: none !important;
    border-radius: 10px !important;
    background-color: rgba(255, 255, 255, 0.08) !important;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Rubik', sans-serif;
    box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0 1px 0;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: rgb(38, 53, 67);
}

.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.StripeElement.PaymentRequestButton {
    height: 40px;
}

.DayPicker {
    background-color: var(--calendar-bg) !important;
}

.DayPicker_weekHeader_li {
    color: var(--calendar-white) !important;
}

.CalendarDay__default {
    background: transparent !important;
    color: var(--calendar-white) !important;
    border: var(--calendar-border) !important;
}

.CalendarDay__default:hover {
    background: var(--calendar-hover-span) !important;
    color: var(--calendar-white) !important;
}

.CalendarMonth {
    background-color: var(--calendar-bg) !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
    color: var(--calendar-white) !important;
}

.CalendarMonthGrid {
    background-color: var(--calendar-bg) !important;
}

.CalendarDay__selected_span {
    background: var(--calendar-selected-span) !important;
    color: var(--calendar-white) !important;
    border: var(--calendar-border) !important;
}

.CalendarDay__selected {
    background: var(--calendar-selcted) !important;
    color: var(--calendar-white) !important;
    border: var(--calendar-border) !important;
}

.CalendarDay__selected:hover {
    background: var(--calendar-selcted_hover) !important;
    color: var(--calendar-white) !important;
    border: var(--calendar-border) !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: var(--calendar-hover-span) !important;
}

.DayPickerNavigation_button__default {
    background-color: transparent !important;
    border: 1px solid var(--calendar-selcted) !important;
    color: var(--calendar-white) !important;
}

.DayPickerNavigation_svg__horizontal {
    fill: var(--calendar-selcted) !important;
}

.DayPickerNavigation_button__verticalDefault {
    background: var(--calendar-selcted) !important;
}

.DayPickerNavigation_svg__vertical {
    fill: var(--calendar-white) !important;
}
